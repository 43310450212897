body {
  background-image: url('bg30.png');
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  
}

.Auth-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 5px;
  padding-bottom: 40px;
  border-radius: 10px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 28px;
  color: rgb(34, 34, 34);
  font-weight: 800;
  
}

#donate{
  padding-top: 10px;
  font-weight: 450;
  color:black;

}

label {
  font-size: 14px;
  font-weight: 550;
  color: rgb(34, 34, 34);
 
}
.result-url {
  display: flex;
  align-items: center;
}

.result-url a {
  margin-right: 5px; /* Add some space between the URL and the copy icon */
}

.copy-icon-container {
  display: flex;
  align-items: center;
}

.copy-icon {
  margin-left: 5px;
  font-size: 10px; /* Adjust the size as needed */
  color: #333; /* Adjust the color as needed */
  cursor: pointer;
}

.copy-label {
  font-size: 12px; /* Adjust the size as needed */
  color: #666; /* Adjust the color as needed */
}