.footer {
    background-color: #ababab; /* Adjust the background color as desired */
    color: #000000; /* Adjust the text color as desired */
    padding: 8px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 13px;
  }
  
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer-text {
    margin: 0;
  }
  
  .social-icons {
    padding-left: 10px;
    display: flex;
  }
  
  .social-icon {
    font-size: 20px;
    color: #1d1d1d; /* Adjust the icon color as desired */
    margin-left: 10px;
  }
  
  .social-icon:first-child {
    margin-left: 00;
    color: rgb(255, 73, 73);
  }

  .social-icon:last-child{
    margin-left: 5px;
    color:blueviolet;
    font-size: 20px;
  }